<template>
  <router-link
    v-if="featureType && featureType.slug"
    :to="{
      name: 'details-type-signalement',
      params: { feature_type_slug: featureType.slug },
    }"
    class="feature-type-title"
    :title="featureType.title"
  >
    <img
      v-if="featureType.geom_type === 'point'"
      class="list-image-type"
      src="@/assets/img/marker.png"
      alt="Géométrie point"
    >
    <img
      v-if="featureType.geom_type === 'linestring'"
      class="list-image-type"
      src="@/assets/img/line.png"
      alt="Géométrie ligne"
    >
    <img
      v-if="featureType.geom_type === 'polygon'"
      class="list-image-type"
      src="@/assets/img/polygon.png"
      alt="Géométrie polygone"
    >
    <img
      v-if="featureType.geom_type === 'multipoint'"
      class="list-image-type"
      src="@/assets/img/multimarker.png"
      alt="Géométrie multipoint"
    >
    <img
      v-if="featureType.geom_type === 'multilinestring'"
      class="list-image-type"
      src="@/assets/img/multiline.png"
      alt="Géométrie multiligne"
    >
    <img
      v-if="featureType.geom_type === 'multipolygon'"
      class="list-image-type"
      src="@/assets/img/multipolygon.png"
      alt="Géométrie multipolygone"
    >
    <span
      v-if="featureType.geom_type === 'none'"
      class="list-image-type"
      title="Aucune géométrie"
    >
      <i class="ui icon large outline file" />
    </span>
    <span class="ellipsis">
      {{ featureType.title }}
    </span>
  </router-link>
</template>

<script>
export default {
  name: 'FeatureTypeLink',

  props: {
    featureType : {
      type: Object,
      default: () => {
        return {};
      },
    }
  },
};
</script>


<style scoped>
.feature-type-title {
  display: flex;
  align-items: center;
  line-height: 1.5em;
  width: fit-content;
}
.list-image-type {
  margin-right: 5px;
  height: 25px;
  display: flex;
  align-items: center;
}
.list-image-type > i {
  color: #000000;
  height: 25px;
}
</style>
